import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import FomanticUI from 'vue-fomantic-ui'
import 'fomantic-ui-css/semantic.min.css'
import VueGoogleMaps from '@fawmi/vue-google-maps' 
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ToastPlugin from 'vue-toast-notification';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue3Geolocation from 'vue3-geolocation';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Vue3Geolocation)
  .use(FomanticUI)
  .use(Toast, {
	position: POSITION.TOP_CENTER,
	toastClassName: "toast",
})
//   .use(VueToast, {
//     position: POSITION.TOP_CENTER,
//     toastClassName: 'toast',
//   })
//   .use(ToastPlugin)
  .use(VueGoogleMaps, {
    load: {
			key: "AIzaSyB6GzShqKWz8Tc1Xq1gUAuHzxkyZO-2Iis",
      // language: 'de',
      libraries: 'places',
    },
    autobindAllEvents: true,
  });

app.mount('#app');
